import { configureStore } from '@reduxjs/toolkit';

import globalSnackReducer from './globalSnackSlice';
import businessesReducer from './businessesSlice';
import globalLoadingReducer from './globalLoadingSlice';

export default configureStore({
  reducer: {
    globalSnack: globalSnackReducer,
    businesses: businessesReducer,
    globalLoading: globalLoadingReducer
  }
});
