import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

export default function Contacts () {
  return (
    <Fragment>
      <Helmet>
        <title>Contactos</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Contactos</h1>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <h2>
              ¡Pronto!
            </h2>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  )
}