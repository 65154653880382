import React, { useState, useEffect } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import DashboardComponent from '../components/Dashboard';
import OnRedirecting from '../components/OnRedirecting';

import { fetchBusinesses } from '../store/businessesSlice';

function Dashboard(props) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const businessesStatus = useSelector(state => state.businesses.status)
  
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (businessesStatus === 'idle') {
      dispatch(fetchBusinesses(getAccessTokenSilently));
    }
  }, [dispatch, getAccessTokenSilently, businessesStatus])

  return (
    <DashboardComponent
      container={container}
      handleDrawerToggle={handleDrawerToggle}
      mobileOpen={mobileOpen}
    />
  );
}

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <OnRedirecting />
})