import React from 'react';
import { uploadMainImageBusiness, removeMainImageBusiness } from 'store/businessesSlice'
import { Box, Paper, Typography } from '@mui/material';
import BusinessMainImage from 'components/BusinessMainImage';
import RemoteImage from 'components/RemoteImage';

export default function BusinessImage ({ business }) {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>Imagen principal</Typography>

      <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
        Para la imagen principal utilizamos una formato cuadrado, y 
        recomendamos que la imagen sea de a lo menos 512x512 pixeles
      </Typography>

      <Box sx={{ mt: 3 }}>
        <RemoteImage
          previewHeight={341}
          previewWidth={341}
          title="Imagen principal"
          toUploadDispatch={(file, token) => uploadMainImageBusiness({ business, file, token })}
          modelHasImage={!!business.hashMainImage}
          imageElement={<BusinessMainImage business={business} />}
          toRemoveDispatch={(token) => removeMainImageBusiness({ business, token })}
        />
      </Box>
    </Paper>
  )
}