import { createSlice } from '@reduxjs/toolkit';

import {
  fetchBusinesses,
  createBusiness,
  editBusiness,
  deleteBusiness,
  uploadMainImageBusiness,
  removeMainImageBusiness,
  toggleConfigBusiness
} from './businessesSlice';

const open = (state) => {
  state.open = true;
}

const close = (state) => {
  state.open = false;
}

export const globalLoadingSlice = createSlice({
  name: 'globalLoading',
  initialState: {
    open: false
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBusinesses.pending, open)
      .addCase(fetchBusinesses.fulfilled, close)

      .addCase(createBusiness.pending, open)
      .addCase(createBusiness.rejected, close)
      .addCase(createBusiness.fulfilled, close)

      .addCase(editBusiness.pending, open)
      .addCase(editBusiness.rejected, close)
      .addCase(editBusiness.fulfilled, close)

      .addCase(deleteBusiness.pending, open)
      .addCase(deleteBusiness.rejected, close)
      .addCase(deleteBusiness.fulfilled, close)

      .addCase(uploadMainImageBusiness.pending, open)
      .addCase(uploadMainImageBusiness.rejected, close)
      .addCase(uploadMainImageBusiness.fulfilled, close)

      .addCase(removeMainImageBusiness.pending, open)
      .addCase(removeMainImageBusiness.rejected, close)
      .addCase(removeMainImageBusiness.fulfilled, close)

      .addCase(toggleConfigBusiness.pending, open)
      .addCase(toggleConfigBusiness.rejected, close)
      .addCase(toggleConfigBusiness.fulfilled, close)

  }
});

export const { add, remove } = globalLoadingSlice.actions

export default globalLoadingSlice.reducer