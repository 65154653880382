import React, { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import { useAuth0 } from "@auth0/auth0-react";

import { editBusiness, deleteBusiness } from 'store/businessesSlice';
import BusinessForm from '../BusinessForm';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function GeneralInfo ({ business }) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const onSubmit = useCallback(async (editedBusiness) => {
    try {
      const token = await getAccessTokenSilently();
      await dispatch(editBusiness({
        business: {
          ...editedBusiness,
          _id: business._id
        }, token
      })).unwrap();
    } catch (err) {
      console.error('Failed to edit the business: ', err)
    }
  }, [dispatch, business, getAccessTokenSilently]);

  const onDelete = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await dispatch(deleteBusiness({
        business, token
      })).unwrap();
      navigate("/mis/negocios")
    } catch (err) {
      console.error('Failed to delete the business: ', err)
    }
  }, [dispatch, getAccessTokenSilently, business, navigate]);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <BusinessForm onSubmit={onSubmit} data={business} onDelete={onDelete} />
    </Paper>
  )
}