import { createSlice } from '@reduxjs/toolkit';

import {
  createBusiness,
  editBusiness,
  deleteBusiness,
  uploadMainImageBusiness,
  removeMainImageBusiness,
  toggleConfigBusiness
} from './businessesSlice';


export const globalSnackSlice = createSlice({
  name: 'globalSnack',
  initialState: {
    snacks: []
  },
  reducers: {
    add: (state, action) => {
      state.snacks.push(action.payload)
    },
    remove: (state, action) => {
      state.snacks.splice(action.payload, 1)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `${action.payload.bname} fue registrado con éxito`,
          open: true,
          severity: 'success'
        })
      })
      .addCase(createBusiness.rejected, (state, action) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `Tuvimos un problema al registrar`,
          open: true,
          severity: 'warning'
        })
      })

      .addCase(editBusiness.fulfilled, (state, action) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `${action.payload.bname} fue editado con éxito`,
          open: true,
          severity: 'success'
        })
      })
      .addCase(editBusiness.rejected, (state, action) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `Tuvimos un problema al editar`,
          open: true,
          severity: 'warning'
        })
      })

      .addCase(deleteBusiness.fulfilled, (state, action) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `El negocio fue eliminado con éxito`,
          open: true,
          severity: 'success'
        })
      })
      .addCase(deleteBusiness.rejected, (state, action) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `Tuvimos un problema al eliminar`,
          open: true,
          severity: 'warning'
        })
      })

      .addCase(uploadMainImageBusiness.rejected, (state) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `Tuvimos un problema al cargar la imagen`,
          open: true,
          severity: 'warning'
        })
      })
      .addCase(uploadMainImageBusiness.fulfilled, (state) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `La imagen fue cargada con éxito`,
          open: true,
          severity: 'success'
        })
      })

      .addCase(removeMainImageBusiness.rejected, (state) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `Tuvimos un problema al eliminar la imagen`,
          open: true,
          severity: 'warning'
        })
      })
      .addCase(removeMainImageBusiness.fulfilled, (state) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `La imagen fue eliminada con éxito`,
          open: true,
          severity: 'success'
        })
      })

      .addCase(toggleConfigBusiness.rejected, (state) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `Tuvimos un problema`,
          open: true,
          severity: 'warning'
        })
      })
      .addCase(toggleConfigBusiness.fulfilled, (state) => {
        state.snacks.push({
          key: 'snack_' + Date.now(),
          message: `Configuración modificada con éxito`,
          open: true,
          severity: 'success'
        })
      })
  }
});

export const { add, remove } = globalSnackSlice.actions

export default globalSnackSlice.reducer