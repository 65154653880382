import React, { useCallback, useState } from 'react';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';

export default function AppBarSessionBox () {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget);
  }, []);
  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const { user, logout } = useAuth0();

  const logoutWithRedirect = useCallback(() => {
    logout({
        logoutParams: {
          returnTo: 'https://espressoemprendedor.cl',
        }
    });
  }, [logout]);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={user.given_name}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={user.name} src={user.picture} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="name">
          <Typography textAlign="center">{user.name}</Typography>
        </MenuItem>
        <MenuItem key="logout" onClick={() => logoutWithRedirect()}>
          <Typography textAlign="center">Cerrar sesión</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}