import { Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useInfiniteHits } from 'react-instantsearch';

export function InfiniteHits({ hitComponent: HitComponent, ...props }) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <Grid container spacing={2} sx={{ minWidth: '300px'}}>
      {hits.map((hit) => (
        <Grid item xs={12} lg={6} key={hit.objectID}>
          <HitComponent hit={hit} />
        </Grid>
      ))}
      <span ref={sentinelRef} aria-hidden="true" />
    </Grid>
  );
}
