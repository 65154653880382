import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get as getBusinesses, save as saveBusiness, del as delBusiness, mainImage } from '../services/business.service'
import { upload as imageDirectUpload } from 'services/image.service';

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
}

export const businessesSlice = createSlice({
  name: 'businesses',
  initialState: {
    data: [],
    status: STATUS.IDLE,
    error: ''
  },
  reducers: {
    add: (state, action) => {
      state.data.push(action.payload)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBusinesses.pending, (state, action) => {
        state.status = STATUS.LOADING
      })
      .addCase(fetchBusinesses.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED
        // Add any fetched posts to the array
        state.data = action.payload
      })
      .addCase(fetchBusinesses.rejected, (state, action) => {
        state.status = STATUS.FAILED
        state.error = action.error.message
      })

      .addCase(createBusiness.fulfilled, (state, action) => {
        state.data.push(action.payload)
      })
      .addCase(editBusiness.fulfilled, (state, action) => {
        const i = state.data.findIndex(b => {
          return b._id === action.payload._id
        })
        state.data[i] = action.payload;
      })
      .addCase(deleteBusiness.fulfilled, (state, action) => {
        const i = state.data.findIndex(b => {
          return b._id === action.payload._id
        })
        state.data.splice(i, 1)
      })

      .addCase(uploadMainImageBusiness.fulfilled, (state, action) => {
        const i = state.data.findIndex(b => {
          return b._id === action.payload.business._id
        })
        state.data[i] = action.payload.business;
      })
      
      .addCase(removeMainImageBusiness.fulfilled, (state, action) => {
        const i = state.data.findIndex(b => {
          return b._id === action.payload.business._id
        })
        state.data[i] = action.payload.business;
      })

      .addCase(toggleConfigBusiness.fulfilled, (state, action) => {
        const i = state.data.findIndex(b => {
          return b._id === action.payload.business._id
        })
        state.data[i] = action.payload.business;
      })
  }
})

// Action creators are generated for each case reducer function
export const { set, add } = businessesSlice.actions
export default businessesSlice.reducer

// SELCTORS
export const selectAllBusinesses = state => state.businesses.data

export const selectBusinessById = (state, businessId) =>
  state.businesses.data.find(business => business._id === businessId);

// API

export const fetchBusinesses = createAsyncThunk(
  'businesses/fetch',
  async (getToken, { rejectWithValue }) => {
    const token = await getToken();
    const { data, error } = await getBusinesses(token);
    if (error) return rejectWithValue(error);
    return data
  }
);

export const createBusiness = createAsyncThunk(
  'businesses/create',
  async ({ business, token }, { rejectWithValue }) => {
    const { data, error } = await saveBusiness(business, token)
    if (error) return rejectWithValue(error);
    return data
  }
);

export const editBusiness = createAsyncThunk(
  'businesses/edit',
  async ({ business, token }, { rejectWithValue }) => {
    const { data, error } = await saveBusiness(business, token);
    if (error) return rejectWithValue(error);
    return data
  }
);

export const deleteBusiness = createAsyncThunk(
  'businesses/delete',
  async ({ business, token }, { rejectWithValue }) => {
    const { data, error } = await delBusiness(business, token);
    if (error) return rejectWithValue(error);
    return data
  }
);

export const uploadMainImageBusiness = createAsyncThunk(
  'businesses/uploadMainImage',
  async ({ business, file, token }, { rejectWithValue }) => {
    const { data, error } = await mainImage(business, token);
    
    if (error) return rejectWithValue(error);

    const { data: uploadData, error: uploadError } = await imageDirectUpload(data.uploadURL, file);
    
    if (uploadError) return rejectWithValue(uploadError);

    const { data: newBusiness,  error: saveError } = await saveBusiness({
      ...business,
      hashMainImage: data.id
    }, token)

    if (saveError) return rejectWithValue(saveError);

    return { business: newBusiness, uploadData }
  }
);

export const removeMainImageBusiness = createAsyncThunk(
  'businesses/removeMainImage',
  async ({ business, token }, { rejectWithValue }) => {
    const { data: newBusiness,  error: saveError } = await saveBusiness({
      _id: business._id,
      hashMainImage: null
    }, token)

    if (saveError) return rejectWithValue(saveError);

    return { business: newBusiness }
  }
);

export const toggleConfigBusiness = createAsyncThunk(
  'businesses/toggleConfig',
  async ({ business, param, token }, { rejectWithValue }) => {
    if (!Object.hasOwn(business, param)) {
      return rejectWithValue('wrong param');
    }
    const { data: newBusiness,  error: saveError } = await saveBusiness({
      _id: business._id,
      [param]: !business[param]
    }, token)

    if (saveError) return rejectWithValue(saveError);

    return { business: newBusiness, param }
  }
);