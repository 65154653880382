import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram, faFacebook, faYoutube, faLinkedin, faTiktok, faXTwitter, faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessDelDialog from './BusinessDelDialog';

const maxLengthCustom = (num) => ({ value: num, message: `El texto debe contener menos de ${num} caracteres` });
const urlPattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
const phonePatternCustom = { value:  /^[0-9]{8}$/, message: 'El número está incompleto o contiene caracteres inválidos' };
const urlPatternCustom = {
  value: urlPattern,
  message: 'La URL no es estándar'
};
const linkedinPatternCustom = { value: /^(in|company)\/.*/, message: 'Revisa la dirección, debe iniciar con "in/" para personas o con "company/" para negocios.'}

const CustomStack = ({children, ...props}) => {
  return (
    <Stack
      {...props}
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      sx={{marginBottom: 2}}
      >{children}</Stack>
  )
}

const BusinessForm = ({ onSubmit, data, onDelete }) => {
  const { register, handleSubmit, reset, formState: { errors }, control } = useForm({ defaultValues: data });
  const [ delDialOpen, setDelDialOpen ] = useState(false);

  const handleDelDialToggle = () => {
    setDelDialOpen(!delDialOpen);
  };

  useEffect(() => {
    reset(data)
  }, [data, reset])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <CustomStack>
        <TextField
          {...register("bname", {
            required: 'Campo requerido',
            maxLength: maxLengthCustom(100)
          })}
          label="Nombre de Fantasía o Razón Social *"
          defaultValue=""
          variant="standard"
          error={!!errors.bname}
          helperText={errors.bname && errors.bname.message}
          fullWidth
        />
        <TextField 
          {...register("area", {
            required: 'Campo requerido',
            maxLength: maxLengthCustom(100)
          })}
          label="Rubro o área *"
          variant="standard"
          error={!!errors.area}
          helperText={errors.area && errors.area.message}
          fullWidth
        />
      </CustomStack>
      
      <CustomStack>
        <TextField
          {...register("description", {
            required: 'Campo requerido',
            maxLength: maxLengthCustom(5000)
          })}
          label={`Acerca de *`}
          variant="standard"
          multiline
          rows={7}
          error={!!errors.description}
          helperText={errors.description && errors.description.message}
          fullWidth
        />
      </CustomStack>

      <CustomStack>
        <FormControl variant="standard" fullWidth
          error={!!errors.formalized}
        >
          <InputLabel id="formalizado-select-label">¿Está formalizado? *</InputLabel>
          <Controller
            rules={{ required: 'Campo requerido' }}
            control={control}
            name="formalized"
            defaultValue=""
            render={({ field }) => (
                <Select
                  labelId="formalizado-select-label"
                  label="¿Está formalizado? *"
                  {...field}
                >
                  <MenuItem value="">
                    <em>Seleccione</em>
                  </MenuItem>
                  <MenuItem value={1}>Si</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              )}
            />
            <FormHelperText>{errors.formalized && errors.formalized.message}</FormHelperText>
        </FormControl>

        <FormControl variant="standard" fullWidth error={!!errors.ownerlegal}>
          <InputLabel id="ownerlegal-label">¿Eres dueño/a o representante legal? *</InputLabel>
          <Controller
            rules={{ required: 'Campo requerido' }}
            control={control}
            name="ownerlegal"
            defaultValue=""
            render={({ field }) => (
              <Select
                labelId="ownerlegal-label"
                label="¿Eres el representante legal? *"
                {...field}
              >
                <MenuItem value="">
                  <em>Seleccione</em>
                </MenuItem>
                <MenuItem value={1}>Si</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            )}
          />
          <FormHelperText>{errors.ownerlegal && errors.ownerlegal.message}</FormHelperText>
        </FormControl>
      </CustomStack>

      <Typography variant="h5" sx={{ mb:1, mt:5 }}>Web y Social</Typography>
      
      <CustomStack>
        <TextField 
          {...register("web", {
            maxLength: maxLengthCustom(500),
            pattern: urlPatternCustom
          })}
          label="Web"
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start" >
                <LanguageIcon />&nbsp;&nbsp;https://
              </InputAdornment>,
          }}
          fullWidth
          placeholder='www.misitio.cl'
          error={!!errors.web}
          helperText={errors.web && errors.web.message}
        />
      </CustomStack>

      <CustomStack>
        <TextField 
          {...register("social.instagram", {
            maxLength: maxLengthCustom(500)
          })}
          label={<Typography><FontAwesomeIcon icon={faInstagram} /> Instagram</Typography>}
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start" >
                <AlternateEmailIcon fontSize='small' />
              </InputAdornment>,
          }}
          fullWidth
        />
        <TextField 
          {...register("social.facebook", {
            maxLength: maxLengthCustom(500)
          })}
          label={<Typography><FontAwesomeIcon icon={faFacebook} /> Facebook</Typography>}
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start">
                .com/
              </InputAdornment>,
          }}
          fullWidth
        />
      </CustomStack>

      <CustomStack>
        <TextField 
          {...register("social.youtube", {
            maxLength: maxLengthCustom(500)
          })}
          label={<Typography><FontAwesomeIcon icon={faYoutube} /> YouTube</Typography>}
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start">
                <AlternateEmailIcon fontSize='small' />
              </InputAdornment>,
          }}
          fullWidth
        />
        <TextField 
          {...register("social.tiktok", {
            maxLength: maxLengthCustom(500)
          })}
          label={<Typography><FontAwesomeIcon icon={faTiktok} /> TikTok</Typography>}
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start">
                <AlternateEmailIcon fontSize='small' />
              </InputAdornment>,
          }}
          fullWidth
        />
      </CustomStack>

      <CustomStack>
        <TextField 
          {...register("social.linkedin", {
            maxLength: maxLengthCustom(500),
            pattern: linkedinPatternCustom
          })}
          label={<Typography><FontAwesomeIcon icon={faLinkedin} /> LinkedIn</Typography>}
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start">
                <Typography fontSize={'small'}>/</Typography>
              </InputAdornment>,
          }}
          fullWidth
          placeholder='in/mi-nombre o company/mi-negocio'
          error={!!errors.social?.linkedin}
          helperText={errors.social?.linkedin && errors.social?.linkedin.message}
        />
        <TextField 
          {...register("social.xtwitter", {
            maxLength: maxLengthCustom(500)
          })}
          label={<Typography><FontAwesomeIcon icon={faXTwitter} /> X.com (ex Twitter)</Typography>}
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start">
                <AlternateEmailIcon fontSize='small' />
              </InputAdornment>,
          }}
          fullWidth
        />
      </CustomStack>
        <TextField 
          {...register("social.whatsapp", {
            maxLength: maxLengthCustom(8),
            pattern: phonePatternCustom,
          })}
          label={<Typography><FontAwesomeIcon icon={faWhatsapp} /> WhatsApp</Typography>}
          variant="standard"
          InputProps={{
            startAdornment: 
              <InputAdornment position="start">
                +56 9
              </InputAdornment>,
          }}
          placeholder='1234 5678'
          fullWidth
          error={!!errors.social?.whatsapp}
          helperText={errors.social?.whatsapp && errors.social?.whatsapp.message}
        />
      <CustomStack>

      </CustomStack>

      <Grid container>
        <Grid item flex={1} sx={{ mb: 2 }}>
          <Button
            variant='contained'
            color='secondary'
            type="submit"
          >{ data?._id ? 'Guardar' : 'Crear y continuar' }</Button>
        </Grid>
        { data && 
          <Grid item>
            <Button
              variant='outlined'
              color='error'
              onClick={handleDelDialToggle}
            >Eliminar</Button>
          </Grid>
        }
      </Grid>
      
      <BusinessDelDialog
        open={delDialOpen}
        handleClose={handleDelDialToggle}
        handleDelete={() => { handleDelDialToggle(); onDelete(); }}
      />
    </form>
  );
}

export default BusinessForm;
