import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux'

import { selectBusinessById, STATUS } from 'store/businessesSlice';
import { Link, NavLink, useParams } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

import BusinessEditComponent from 'components/BusinessEdit'

export default function BusinessEditRoute () {
  let { businessId } = useParams();
  const [value, setValue] = useState('geninfo');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  let business = useSelector(state => selectBusinessById(state, businessId));
  const businessesStatus = useSelector(state => state.businesses.status)

  useEffect(() => {
    document.getElementsByTagName('main')[0].scroll({top: 0, left: 0, behavior: "smooth"})
  }, []);
  
  if (businessesStatus !== STATUS.SUCCEEDED) {
    return null
  }

  if (!business) return null;

  return (
    <Fragment>
      <Helmet><title>Editar negocio</title></Helmet>
      <Breadcrumbs style={{ marginBottom: '1em' }}>
        <Link
          underline="hover"
          color="inherit"
          to="/mis/negocios"
          component={NavLink}
        >
          Mis Negocios
        </Link>
        <Typography color="text.primary">{business?.bname}</Typography>
      </Breadcrumbs>
      <BusinessEditComponent business={business} handleChange={handleChange} value={value} />
    </Fragment>
  )
}