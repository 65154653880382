import axios from 'axios';

export const upload = async (directURL, file) => {
  try {
    let formData = new FormData();
    formData.append("file", file);

    const { data } = await axios.post(directURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { data };
  } catch(e) {
    return { error: e }
  }
}
