import React from 'react';
import { Outlet } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Chip} from '@mui/material';

import Copyright from '../components/Copyright';
import AppBarSessionBox from '../components/AppBarSessionBox';

import Drawer, { drawerWidth } from '../components/Drawer';
import GlobalSnack from './GlobalSnack';
import GlobalLoading from './GlobalLoading';

function Dashboard(props) {
  const {
    handleDrawerToggle,
    mobileOpen,
    container,
  } = props
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="div"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Espresso Emprendedor
            <Chip label="alpha" variant="filled" size='small' color='secondary' sx={{ marginLeft: '1em'}} />
          </Typography>

          <AppBarSessionBox />
        </Toolbar>
      </AppBar>
      <Box 
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        <Drawer mobileOpen={mobileOpen} container={container} handleDrawerToggle={handleDrawerToggle} />
      </Box>
      <Box
        component="main"
        sx={{ 
          flexGrow: 1, 
          p: 3, 
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }}>
          <Outlet />
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
      <GlobalSnack />
      <GlobalLoading />
    </Box>
  );
}

export default Dashboard;
