import { Box, Card, CardActions, CardContent, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { srcMainImage } from './BusinessMainImage';
import LanguageIcon from '@mui/icons-material/Language';
import normalizeUrl from 'normalize-url';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram, faFacebook, faYoutube, faLinkedin, faTiktok, faXTwitter, faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

export default function BusinessPage ({ business }) {
  return (
    <Grid container sx={{ minWidth: '300px' }}>
      <Grid item xs={12}>
        <Card sx={{ display: 'flex' }}>
          { business.hashMainImage && <CardMedia
            component='img'
            image={srcMainImage(business)}
            sx={{ width: 151, maxWidth: '50%', display: { xs: 'none', xsm: 'block'} }}
            alt={business.bname}
          />}
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden', wbusinesseSpace: 'nowrap' }}>
            <CardContent
              sx={{
                flex: '1 0 auto',
                background: {xs: `center / cover no-repeat rgba(255,255,255,0.65) url(${business.hashMainImage ? srcMainImage(business) : ''})`, xsm: 'none' },
                backgroundBlendMode: 'overlay',
              }}
            >
              <Typography variant='subtitle1' color="text.secondary" gutterBottom>
                {business.area}
              </Typography>
              <Typography variant='h5' gutterBottom>
                {business.bname}
              </Typography>
            </CardContent>
            <CardActions sx={{ overflowX: 'scroll' }}>
              { business.web &&
                <IconButton aria-label="ir al sitio" href={normalizeUrl(business.web)} target='_blank'>
                  <LanguageIcon />
                </IconButton>
              }
              { business.social.whatsapp && !Number.isNaN(Number(business.social.whatsapp)) && 
                <IconButton aria-label="ir al sitio" href={`https://wa.me/+569${business.social.whatsapp}`} target='_blank'>
                  <FontAwesomeIcon icon={faWhatsapp} />
                </IconButton>
              }
              { business.social.instagram && 
                <IconButton aria-label="ir al sitio" href={normalizeUrl(`https://instagram.com/${business.social.instagram}`)} target='_blank'>
                  <FontAwesomeIcon icon={faInstagram} />
                </IconButton>
              }
              { business.social.linkedin && 
                <IconButton aria-label="ir al sitio" href={normalizeUrl(`https://linkedin.com/${business.social.linkedin}`)} target='_blank'>
                  <FontAwesomeIcon icon={faLinkedin} />
                </IconButton>
              }
              { business.social.xtwitter && 
                <IconButton aria-label="ir al sitio" href={normalizeUrl(`https://twitter.com/${business.social.xtwitter}`)} target='_blank'>
                  <FontAwesomeIcon icon={faXTwitter} />
                </IconButton>
              }
              { business.social.tiktok && 
                <IconButton aria-label="ir al sitio" href={normalizeUrl(`https://www.tiktok.com/@${business.social.tiktok}`)} target='_blank'>
                  <FontAwesomeIcon icon={faTiktok} />
                </IconButton>
              }
              { business.social.youtube && 
                <IconButton aria-label="ir al sitio" href={normalizeUrl(`https://www.youtube.com/@${business.social.youtube}`)} target='_blank'>
                  <FontAwesomeIcon icon={faYoutube} />
                </IconButton>
              }
              { business.social.facebook && 
                <IconButton aria-label="ir al sitio" href={normalizeUrl(`https://www.facebook.com/${business.social.facebook}`)} target='_blank'>
                  <FontAwesomeIcon icon={faFacebook} />
                </IconButton>
              }
            </CardActions>
          </Box>
        </Card>
      </Grid>
      <Grid item mt={2} xs={12}>
        <Card>
          <CardContent>
            <Typography variant="body2" whiteSpace={'pre-wrap'}>
              {business.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}