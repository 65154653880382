import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import ErrorPage from "./ErrorPage";
import HomeNoBusiness from '../components/HomeNoBusiness';
import HomeComponent from '../components/Home';

import { STATUS } from '../store/businessesSlice';

export default function Home () {
  const { user } = useAuth0();
  const { data: businesses, status, error } = useSelector(state => state.businesses);

  if (status === STATUS.FAILED) {
    return (
      <ErrorPage errorProp={error} />
    )
  }

  if (status !== STATUS.SUCCEEDED) {
    return null
  }

  if (!businesses.length) return (<HomeNoBusiness user={user} />);

  return (
    <HomeComponent user={user} />
  )
}