import React from 'react';

export const srcMainImage = (business, variant = 'square256') => {
  return `${process.env.REACT_APP_IMAGE_DELIVERY}/${business.hashMainImage}/${variant}`;
}

export default function BusinessMainImage ({ business, variant }) {
  const mainURL = srcMainImage(business, variant);

  return (
    <img
      src={mainURL}
      alt={business.bname}
      width={256}
      height={256}
    />
  )
}