import React from 'react';
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";

export default function OnRedirecting () {
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </React.Fragment>
  )
}