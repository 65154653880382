import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

export default function RemoteImage (props) {
  const {
    variant = 'outlined',
    color = 'primary',
    id = 'fileup',
    title,
    previewWidth = 256,
    previewHeight = 256,
    modelHasImage,
    imageElement,
    toUploadDispatch,
    toRemoveDispatch
  } = props;

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const fileInputRef = React.createRef();
  
  const handleLoad = useCallback(async (file) => {
    try {
      setLoading(true)
      const token = await getAccessTokenSilently();
      await dispatch(toUploadDispatch(file, token)).unwrap()
      setFile(null);
    } catch (err) {
      console.error('Failed to upload image: ', err, err.stack)
    } finally {
      setLoading(false)
    }
  }, [getAccessTokenSilently, dispatch, toUploadDispatch]);

  const handleRemove = useCallback(async () => {
    try {
      setLoading(true)
      const token = await getAccessTokenSilently();
      await dispatch(toRemoveDispatch(token)).unwrap()
      setFile(null);
    } catch (err) {
      console.error('Failed to remove image: ', err, err.stack)
    } finally {
      setLoading(false)
      setConfirmDelete(false)
    }
  }, [getAccessTokenSilently, dispatch, toRemoveDispatch])

  const fileChange = useCallback(event => {
    event.preventDefault();
    // validar que pese menos de 8mb 8000000b file.size
    setFile(event.target.files[0]);
  }, []);

  const handleCancel = useCallback(() => {
    setFile(null);
  }, [])

  return (
    <Box sx={{position: 'relative'}}>
      { modelHasImage ?
        <Grid container direction={'column'} alignItems={'center'}>
          {imageElement}
          <Button
            variant="outlined"
            size='small'
            startIcon={<EditIcon />}
            onClick={() => fileInputRef.current.click()}
            sx={{ mt: '1em' }}
          >
            Cambiar imagen
          </Button>
          <Button
            variant="outlined"
            size='small'
            startIcon={<DeleteIcon />}
            onClick={() => setConfirmDelete(true)}
            color='warning'
          >
            Eliminar imagen
          </Button>
        </Grid>
        :
        <Button
          variant={variant}
          color={color}
          startIcon={<AddPhotoAlternateIcon />}
          onClick={() => fileInputRef.current.click()}
        >
          Agregar imagen
        </Button>
      }
      <input
        ref={fileInputRef}
        type='file'
        accept="image/png, image/jpeg"
        id={id}
        style={{ display: 'none' }}
        onChange={fileChange}
      />
      { !!file && 
        <Dialog open>
          {title && <DialogTitle>Vista previa</DialogTitle> }
          <DialogContent dividers>
            <DialogContentText gutterBottom>{title}</DialogContentText>
            <img src={URL.createObjectURL(file)} height={previewHeight} width={previewWidth} alt='Vista previa'
              style={{ objectFit: 'scale-down', maxWidth: '100%' }}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel} disabled={loading}>
              Cancelar
            </Button>
            <Button
              onClick={() => handleLoad(file)}
              color='secondary'
              variant='contained'
              disabled={loading}
              endIcon={loading && <CircularProgress color='secondary' size={20} />}
            >
              { loading ? 'Cargando' : 'Cargar' }
            </Button>
          </DialogActions>
        </Dialog>
      }
      { !!confirmDelete && 
        <Dialog open>
          <DialogTitle>Eliminar imagen</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Por favor confirma la eliminación de la imagen. Una vez realizada
              la acción no se podrá recuperar.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setConfirmDelete(false)} disabled={loading}>
              Cancelar
            </Button>
            <Button
              onClick={handleRemove}
              color='warning'
              variant='contained'
              disabled={loading}
              endIcon={loading && <CircularProgress color='secondary' size={20} />}
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  )
}

RemoteImage.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  previewWidth: PropTypes.number,
  previewHeight: PropTypes.number,
  modelHasImage: PropTypes.bool.isRequired,
  imageElement: PropTypes.node.isRequired,
  toUploadDispatch: PropTypes.func.isRequired,
  toRemoveDispatch: PropTypes.func.isRequired
};