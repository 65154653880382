import { Alert, AlertTitle, Grid, Typography } from '@mui/material';
import BusinessDiscovery from './BusinessDiscovery';

const HomeBusiness = ({ user }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>Hola {user.given_name} 🙌</Typography>
        
        <Alert severity="info">
          <AlertTitle>Próximas reuniones</AlertTitle>
          Estaremos informando las próximas reuniones por medio de la <a href='https://chat.whatsapp.com/CbRjtJQLwVvKhjwlOpdFyh'>comunidad de WhatsApp</a>
        </Alert>
        
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>Conoce Espresso Emprendedores</Typography>
        <BusinessDiscovery />
      </Grid>
    </Grid>
  )
}

export default HomeBusiness;