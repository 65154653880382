import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { selectAllBusinesses, STATUS } from '../store/businessesSlice';
import BusinessCard from '../components/BusinessCard';
import BusinessCardNew from '../components/BusinessCardNew';
import { Fragment } from 'react';

export default function MyBusinesses () {
  const businesses = useSelector(state => selectAllBusinesses(state))
  const businessesStatus = useSelector(state => state.businesses.status)
  if (businessesStatus !== STATUS.SUCCEEDED) {
    return null
  }

  return (
    <Fragment>
      <Helmet><title>Mis negocios</title></Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Mis Negocios</h1>
          <Grid container spacing={2} sx={{ minWidth: '300px'}}>
            { businesses && businesses.map(b => 
              <Grid item xs={12} lg={6} key={b._id}>
                <BusinessCard business={b} />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <BusinessCardNew />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}