import { Fragment, useCallback, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from 'react-redux'

import Paper from '@mui/material/Paper';

import BusinessForm from '../components/BusinessForm';
import { createBusiness } from '../store/businessesSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

export default function BusinessNew () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { getAccessTokenSilently } = useAuth0();
  
  const onSubmit = useCallback(async (business) => {
    try {
      const token = await getAccessTokenSilently();
      const newb = await dispatch(createBusiness({business, token})).unwrap();
      navigate(`/mis/negocios/editar/${newb._id}`);
    } catch (err) {
      console.error('Failed to save the post: ', err)
    }
  }, [getAccessTokenSilently, dispatch, navigate]);

  useEffect(() => {
    document.getElementsByTagName('main')[0].scroll({top: 0, left: 0, behavior: "smooth"})
  }, []);
  
  return (
    <Fragment>
      <Helmet><title>Nuevo negocio</title></Helmet>
      <Breadcrumbs style={{ marginBottom: '1em' }}>
        <Link
          underline="hover"
          color="inherit"
          to="/mis/negocios"
          component={NavLink}
        >
          Mis Negocios
        </Link>
        <Typography color="text.primary">Nuevo negocio</Typography>
      </Breadcrumbs>

      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <BusinessForm onSubmit={onSubmit} />
      </Paper>
      
    </Fragment>
  )
}