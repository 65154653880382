import MuiDrawer from '@mui/material/Drawer';
import { Fragment } from 'react';
import { Divider, List, Toolbar } from '@mui/material';
import { mainListItems } from './navItems';

export const drawerWidth = 240;

const DrawerContent = () =>
    <Fragment>
      <Toolbar />
      <Divider />
      <List component="nav">
        {mainListItems}
      </List>
    </Fragment>

const Drawer = ({ container, mobileOpen, handleDrawerToggle}) => {
  return (
    <Fragment>
      <MuiDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onClick={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <DrawerContent />
        </MuiDrawer>
        <MuiDrawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <DrawerContent />
        </MuiDrawer>
    </Fragment>
  )
}

export default Drawer;