import { Box, Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { srcMainImage } from './BusinessMainImage';


export default function BusinessCard ({ business: b }) {
  return (
    <Card sx={{ display: 'flex' }}>
      { b.hashMainImage && <CardMedia
        component='img'
        image={srcMainImage(b)}
        sx={{ width: 151, maxWidth: '50%', display: { xs: 'none', xsm: 'block'} }}
        alt={b.bname}
      />}
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <CardContent
          sx={{
            flex: '1 0 auto',
            background: {xs: `center / cover no-repeat rgba(255,255,255,0.65) url(${b.hashMainImage ? srcMainImage(b) : ''})`, xsm: 'none' },
            backgroundBlendMode: 'overlay',
          }}
        >
          <Typography variant='subtitle1' color="text.secondary" gutterBottom noWrap>
            {b.area}
          </Typography>
          <Typography variant='h5' gutterBottom noWrap>
            {b.bname}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            {b.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing
          sx={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: { xs: "flex-start", xsm: "flex-end" },
            alignItems: "flex-start",
          }}
        >
          <IconButton size="small" LinkComponent={NavLink} to={`/mis/negocios/editar/${b._id}`}>
            <EditIcon fontSize='small'/>
          </IconButton>
        </CardActions>
      </Box>
    </Card>
  )
}