import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export default function FAQ () {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Preguntas Frecuentes</h1>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <h2>
            ¡Pronto!
          </h2>
        </Paper>
      </Grid>
    </Grid>
  )
}