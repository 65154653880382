import api from './api'

const create = async (business, accessToken) => {
  try {
    const { data } = await api.post('/my/businesses', business, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch(e) {
    return { error: e }
  }
}

const edit = async (business, accessToken) => {
  try {
    const { data } = await api.put(`/my/businesses/${business._id}`, business, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch(e) {
    return { error: e }
  }
}

export const del = async (business, accessToken) => {
  try {
    const { data } = await api.delete(`/my/businesses/${business._id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch(e) {
    return { error: e }
  }
}

export const save = async (business, accessToken) => {
  if (business._id) return edit(business, accessToken);
  return create(business, accessToken);
}

export const get = async (accessToken) => {
  try {
    const { data } = await api.get('/my/businesses', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return { data };
  } catch(e) {
    return { error: e };
  }
}

export const mainImage = async (business, accessToken) => {
  try {
    const { data } = await api.post(
      `/my/business/${business._id}/imageURL/main`,
      {
        identifier: 'main'
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return { data };
  } catch(e) {
    return { error: e };
  }
}

export const getPublicData = async (_id, accessToken) => {
  try {
    const { data } = await api.get(`/businesses/${_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (e) {
    return { error: e };
  }
}