import React, { useCallback } from 'react';
import { toggleConfigBusiness } from 'store/businessesSlice'
import { Box, FormControl, FormControlLabel, FormGroup, FormHelperText, Paper, Switch, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

export default function BusinessSearch ({ business }) {
  const { visible } = business;
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  
  const handleChange = useCallback(async (param) => {
    try {
      const token = await getAccessTokenSilently();
      await dispatch(toggleConfigBusiness({
        business, param, token
      })).unwrap();
    } catch (err) {
      console.error('Failed to edit the business: ', err)
    }
  }, [dispatch, getAccessTokenSilently, business]);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>Preferencias de búsqueda y visibilidad</Typography>

      <Box>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={visible} onChange={() => handleChange('visible')} name="visible" value={visible} />
              }
              label="Visible para búsquedas y perfil público"
            />
            <FormHelperText>Tu negocio, productos y/o servicios { visible ? '' : 'no' } serán visibles como resultados de búsqueda.</FormHelperText>
          </FormGroup>
        </FormControl>
      </Box>
    </Paper>
  )
}