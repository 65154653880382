import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { amber, deepPurple } from '@mui/material/colors';

import { Auth0ProviderWithNavigate } from '../auth0-provider-with-navigate';
import { Helmet } from 'react-helmet';

import Dashboard from './Dashboard';
import Home from './Home';
import BusinessNew from './BusinessNew';
import Contacts from './Contacts';

import ErrorPage from "./ErrorPage";
import BusinessEdit from './BusinessEdit';
import MyBusinesses from './MyBusinesses';
import FAQ from './FAQ';
import { InstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import BusinessPage from './BusinessPage';

const defaultTheme = createTheme({
  palette: {
    primary: deepPurple,
    secondary: amber,
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 450,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const searchClient = algoliasearch(
  process.env.REACT_APP_SEARCH_APP_ID,
  process.env.REACT_APP_SEARCH_API_KEY,
  {
    future: {
      preserveSharedStateOnUnmount: true,
    },
    numberLocale: 'es-CL',
  }
);

const App = () => {
  return (
    <Auth0ProviderWithNavigate>
      <ThemeProvider theme={defaultTheme}>
        <InstantSearch
          indexName={process.env.REACT_APP_SEARCH_INDEX}
          searchClient={searchClient}
        >
          <Helmet><title>Espresso Emprendedor</title></Helmet>
          <Dashboard />
        </InstantSearch>
      </ThemeProvider>
    </Auth0ProviderWithNavigate>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />
      },
      /*{
        path: "/hello/:name",
        element: <Hello />
      }*/
      {
        path: "/mis/negocios",
        children: [
          {
            path: "nuevo",
            element: <BusinessNew />
          },
          {
            path: "editar/:businessId",
            element: <BusinessEdit />
          },
          {
            path: '',
            element: <MyBusinesses />,
          },
        ]
      },
      {
        path: "negocios/:businessId",
        element: <BusinessPage />
      },
      {
        path: "contactos",
        element: <Contacts />
      },
      {
        path: "faq",
        element: <FAQ />
      }
    ]
  }
]);

export default router;