import React, { Fragment } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GeneralInfo from './GeneralInfo';
import TabPanel from 'components/TabPanel';
import BusinessImage from './BusinessImage';
import BusinessSearch from './BusinessSearch';

export default function BusinessEditTabs({ business, value, handleChange }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        aria-label="secciones edicion de negocio"
      >
        <Tab value="geninfo" label="Información general" />
        <Tab value="images" label="Imágenes" />
        <Tab value="search" label="Búsqueda" />
      </Tabs>
      <Fragment>
        <TabPanel value={value} index='geninfo'>
          <GeneralInfo business={business} />
        </TabPanel>
        <TabPanel value={value} index='images'>
          <BusinessImage business={business} />
        </TabPanel>
        <TabPanel value={value} index='search'>
          <BusinessSearch business={business} />
        </TabPanel>
      </Fragment>
    </Box>
  );
}