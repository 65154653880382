import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { srcMainImage } from './BusinessMainImage';
import { NavLink as RouterNavLink } from 'react-router-dom';
import Link from '@mui/material/Link'

export default function BusinessCardHit ({ hit }) {
  return (
    <Link to={`/negocios/${hit.objectID}`} underline='none' component={RouterNavLink}>
      <Card sx={{ display: 'flex' }}>
        { hit.hashMainImage && <CardMedia
          component='img'
          image={srcMainImage(hit)}
          sx={{ width: 151, maxWidth: '50%', display: { xs: 'none', xsm: 'block'} }}
          alt={hit.bname}
        />}
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          <CardContent
            sx={{
              flex: '1 0 auto',
              background: {xs: `center / cover no-repeat rgba(255,255,255,0.65) url(${hit.hashMainImage ? srcMainImage(hit) : ''})`, xsm: 'none' },
              backgroundBlendMode: 'overlay',
            }}
          >
            <Typography variant='subtitle1' color="text.secondary" gutterBottom noWrap>
              {hit.area}
            </Typography>
            <Typography variant='h5' gutterBottom noWrap>
              {hit.bname}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {hit.description}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Link>
  )
}