import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { remove } from '../store/globalSnackSlice'
import { Alert, Snackbar } from '@mui/material';

const GlobalSnack = () => {
  const snacks = useSelector((state) => state.globalSnack.snacks)
  const dispatch = useDispatch()
  const snackHandleClose = (i) => () => dispatch(remove(i))

  return (
    <Fragment>
      { snacks.map((s, i) => 
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={true}
          key={s.key}
          autoHideDuration={6000}
          onClose={snackHandleClose(i)}
        >
          <Alert onClose={snackHandleClose(i)} severity={s.severity} sx={{ width: '100%' }}>
            {s.message}
          </Alert>
        </Snackbar>
      )}
    </Fragment>
  )
}

export default GlobalSnack;
