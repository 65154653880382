import { Button, Grid, Paper } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { NavLink } from 'react-router-dom';

const HomeNoBusiness = ({ user }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h1>Hola {user.given_name} 🙌</h1>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <p>
            Estás a punto de conectar con una red de emprendedores, 
            empresas y servicios que potenciarán tu negocio. ¡Cuéntanos más de tí!
          </p>

          <Button
            color='secondary'
            disableElevation
            variant='contained'
            endIcon={<RocketLaunchIcon />}
            sx={{ alignSelf: 'center' }}
            LinkComponent={NavLink}
            to='/negocios/nuevo'
          >
            Registra tu negocio, producto o servicio
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default HomeNoBusiness;