import React, { Fragment, useEffect, useState } from 'react';

import BusinessPageComponent from '../components/BusinessPage';

import { useParams } from 'react-router-dom';
import { getPublicData } from 'services/business.service';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

export default function MyBusinesses () {
  const { getAccessTokenSilently } = useAuth0();
  let { businessId } = useParams();
  const [business, setBusiness] = useState();
  const [error, setError] = useState();
  
  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const { data, error } = await getPublicData(businessId, token);
      if (error) setError(error)
      else setBusiness(data)
    })()

    return () => {}
  }, [businessId, getAccessTokenSilently])

  if (error) {
    return (
      <Typography>No se encuentra el negocio</Typography>
    )
  }

  if (!business) {
    return (
      <CircularProgress />
    )
  }

  return (
    <Fragment>
      <Helmet><title>{ business.bname }</title></Helmet>
      <BusinessPageComponent business={business} />
    </Fragment>
  )
}