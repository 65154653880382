import { InfiniteHits } from './InfiniteHits';
import BusinessCardHit from './BusinessCardHit';
import { Configure, useInstantSearch } from 'react-instantsearch';
import { Fragment } from 'react';
import { LinearProgress } from '@mui/material';

function BusinessDiscovery() {
  const { status } = useInstantSearch();
  const stalled = status === 'stalled';

  return (
    <Fragment>
      { stalled ? <LinearProgress /> : null }
      <Configure
        hitsPerPage={10}
      />
      <InfiniteHits hitComponent={BusinessCardHit} />
    </Fragment>
  );
}

export default BusinessDiscovery;