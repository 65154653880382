import { Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from 'react-router-dom';

export default function BusinessCardNew () {
  return (
    <Card>
      <CardContent>
        <Stack direction='column' xs={12} alignItems={'center'}>
        <IconButton LinkComponent={NavLink} to="/mis/negocios/nuevo">
          <AddIcon fontSize='large' />
          <Typography variant='h6'>Nuevo</Typography>
        </IconButton>
        </Stack>
      </CardContent>
    </Card>
  )
}